<template>
  <div>
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore">
    </avue-form>
  </div>
</template>

<script>
import {listClassify, listSupplier} from "@/api/shop/goods";

export default {
  name: "createOne",
  data(){
    return{
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        column: [
          {
            label: '商品名称',
            prop: 'name',
            maxlength: 30,
            showWordLimit: true,
            span: 12,
            row: true,
            rules: [{
              required: true,
              message: "请输入商品名称",
              trigger: "blur"
            }]
          },
          {
            label: '供应商名称',
            prop: 'supplierId',
            type: 'select',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            span: 12,
            row: true,
            rules: [{
              required: true,
              message: "请选择供应商",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择供应商'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '分类',
            prop: 'classifyId',
            type: 'cascader',
            span: 12,
            row: true,
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            rules: [{
              required: true,
              message: "请选择分类",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择分类'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '排序值',
            prop: 'sort',
            type: 'number',
            span: 12,
            row: true,
            maxRows: 999999,
            minRows: 1,
            rules: [{
              required: true,
              message: "请输入排序值",
              trigger: "blur"
            }]
          },
          {
            label: '虚拟销量',
            prop: 'virtualSales',
            type: 'number',
            span: 12,
            row: true,
            maxRows: 999999,
            minRows: 0
          },
          {
            label: '是否推荐',
            prop: 'isRecommend',
            type: 'select',
            span: 12,
            row: true,
            dicData: [
              {
                value: 1,
                label: '推荐'
              },
              {
                value: 2,
                label: '不推荐'
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否推荐",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择是否推荐'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '是否上架',
            prop: 'state',
            type: 'select',
            span: 12,
            row: true,
            dicData: [
              {
                value: 1,
                label: '上架'
              },
              {
                value: 2,
                label: '不上架'
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否上架",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择是否上架'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '商品封面',
            prop: 'cover',
            type: 'upload',
            listType: 'picture-img',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：宽 338、高 自适应，最大20M',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传商品封面",
              trigger: "blur"
            }],
            change: (e)=>{
              if(e.value !== ''){
                this.$refs.form.clearValidate(`cover`);
              }
            }
          },
          {
            label: '商品视频',
            prop: 'video',
            type: 'upload',
            listType: 'picture-img',
            loadText: '上传中，请稍等',
            accept: 'video/mp4',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.videoUrl,
            tip: '建议尺寸：750*750,最大20M',
            fileSize: 20 * 1024,
            span: 24,
            // rules: [{
            //   required: true,
            //   message: "请上传商品视频",
            //   trigger: "blur"
            // },{
            //   validator: (rule, value, callback) => {
            //     if(value === ''){
            //       callback(new Error('请上传商品视频'));
            //     }else{
            //       callback();
            //     }
            //   }
            // }],
            change: (e)=>{
              if(e.value !== ''){
                this.$refs.form.clearValidate(`video`);
              }
            }
          },
          {
            label: 'banner',
            prop: 'banner',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            limit: 9,
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：750*750,最大20M，最多上传9张',
            fileSize: 20 * 1024,
            // multiple: true,
            span: 24,
            rules: [{
              required: true,
              message: "请上传banner",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请上传banner'));
                }else{
                  callback();
                }
              }
            }],
            change: (e)=>{
              if(e.value !== ''){
                this.$refs.form.clearValidate(`banner`);
              }
            }
          }
        ]
      }
    }
  },
  async mounted() {
    await this.listSupplier()
    await this.listClassify()
    this.$emit('onload')
  },
  methods: {
    async listSupplier(){
      const { data } = await listSupplier()
      // console.log(data)
      const supplierId = this.findObject(this.formOption.column, 'supplierId');
      supplierId.dicData = data || [];
    },
    async listClassify(){
      const { data } = await listClassify()
      // console.log(data)
      const classifyId = this.findObject(this.formOption.column, 'classifyId');
      classifyId.dicData = data || [];
    },
    setForm(form){
      // console.log(form)
      this.form = form;
      this.formOption.column.map(item=>{
        if(item.prop === 'banner'){
          item.propsHttp.name = form.banner
        }
        if(form.banner.split(',').length >= 9){
          this.upload('none');
        }
      })
    },
    submitForm() {
      return new Promise(resolve => {
        this.$refs.form.validate((valid,done) => {
          if (valid) {
            done();
            resolve(this.form)
          } else {
            done();
            resolve(false)
          }
        });
      })
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          if(action === 'confirm'){
            let array = JSON.parse(JSON.stringify(this.form[column.prop].split(',')));
            let index = array.findIndex(item=>item === file.url)
            array.splice(index,1);
            column.propsHttp.name = array.toString();
            if(array.length < column.limit){
              this.upload('inline-block',column.prop);
            }
          }
          done();
        }
      })
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        if(column.prop === 'banner'){
          if(column.propsHttp.name){
            column.propsHttp.name += ',' + res.path
          }else{
            column.propsHttp.name = res.path;
          }
        }else{
          column.propsHttp.name = res.path;
        }
        console.log(column.propsHttp.name.split(',').length);
        if(column.propsHttp.name.split(',').length >= column.limit) {
          console.log(column.propsHttp.name.split(',').length);
          console.log(column.limit);
          this.upload('none');
        }
      }
    },
    upload(type){
      let upload = document.getElementsByClassName('el-upload')[2];
      upload.style.display = type === 'none' ? 'none' : 'inline-block';
    },
  }
}
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon{
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar{
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  object-fit: contain;
}
</style>