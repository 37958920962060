var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"选择规格 :","prop":"specsIds","rules":[
          { required: true, message: '请选择规格', trigger: 'change' }
      ]}},[_c('div',{staticClass:"specsIds"},[_c('el-select',{attrs:{"size":"small","multiple":"","placeholder":"请选择规格"},on:{"change":_vm.changeSpecs},model:{value:(_vm.form.specsIds),callback:function ($$v) {_vm.$set(_vm.form, "specsIds", $$v)},expression:"form.specsIds"}},_vm._l((_vm.specs),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)]),_vm._l((_vm.form.specsList),function(item,index){return _c('div',{key:index},[_c('el-form-item',{attrs:{"label":"规格名称"}},[_c('el-tag',{attrs:{"type":"info","size":"medium","closable":""},on:{"close":function($event){return _vm.handleClose(index)}}},[_vm._v(_vm._s(item.name))])],1),_c('el-form-item',{attrs:{"label":"子规格","prop":("specsList." + index + ".checkList"),"rules":[
            { required: true, message: '请选择子规格', trigger: 'change' }
        ]}},[_c('el-checkbox-group',{model:{value:(item.checkList),callback:function ($$v) {_vm.$set(item, "checkList", $$v)},expression:"item.checkList"}},_vm._l((item.children),function(itemC,indexC){return _c('el-checkbox',{key:itemC.id,attrs:{"label":itemC.id},on:{"change":function($event){return _vm.checked($event,index,indexC)}}},[_vm._v(" "+_vm._s(itemC.name)+" ")])}),1)],1)],1)}),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.form.specsData,"border":"","header-cell-style":{
            background: '#eef1f6',
            color: '#606266',
            textAlign: 'center',
          }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v("暂无规格")])]},proxy:true}])},[_vm._l((_vm.form.specsList),function(item,index){return _c('el-table-column',{key:index,attrs:{"prop":("specs_" + index),"label":item.name,"width":"200px","align":"center"}})}),_c('el-table-column',{attrs:{"label":"规格图片","prop":"image","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".image"),"rules":[
              { required: true, message: '请上传图片', trigger: 'change' }
          ],"label-width":"0px","inline-message":true}},[_c('div',{staticClass:"skuImg"},[_c('UploadImage',{attrs:{"label":"specsData","url":scope.row.image,"type":"list","index":scope.$index},on:{"upload":_vm.upload}})],1)])]}}])}),_c('el-table-column',{attrs:{"label":"*SKU编码","prop":"sku","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".sku"),"rules":[
              { required: true, message: '请输入SKU编码', trigger: 'blur' }
          ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入SKU编码","maxlength":"30","show-word-limit":true,"type":"text"},on:{"input":function($event){return _vm.changeSkuCode($event,'sku',scope.$index)}},model:{value:(scope.row.sku),callback:function ($$v) {_vm.$set(scope.row, "sku", $$v)},expression:"scope.row.sku"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*蜜豆积分","prop":"points","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".points"),"rules":[
              { required: true, message: '请输入蜜豆积分', trigger: 'blur' }
          ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入蜜豆积分","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'points',scope.$index)}},model:{value:(scope.row.points),callback:function ($$v) {_vm.$set(scope.row, "points", $$v)},expression:"scope.row.points"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*成本积分","prop":"costPoints","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".costPoints"),"rules":[
              { required: true, message: '请输入成本积分', trigger: 'blur' }
          ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入成本积分","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event,'costPoints',scope.$index)}},model:{value:(scope.row.costPoints),callback:function ($$v) {_vm.$set(scope.row, "costPoints", $$v)},expression:"scope.row.costPoints"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"重量(kg)","prop":"weight","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".weight"),"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入重量","type":"text","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)"},on:{"blur":function($event){return _vm.salaryChange($event,'weight',scope.$index)}},model:{value:(scope.row.weight),callback:function ($$v) {_vm.$set(scope.row, "weight", $$v)},expression:"scope.row.weight"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"体积(m³)","prop":"volume","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".volume"),"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入体积","type":"text","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)"},on:{"blur":function($event){return _vm.salaryChange($event,'volume',scope.$index)}},model:{value:(scope.row.volume),callback:function ($$v) {_vm.$set(scope.row, "volume", $$v)},expression:"scope.row.volume"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*库存","prop":"stock","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".stock"),"rules":[
              { required: true, message: '请输入库存', trigger: 'change' }
          ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入库存","type":"text","onkeyup":"this.value=this.value.replace(/[^\\d]+/g,'')"},on:{"blur":function($event){return _vm.salaryChange($event,'stock',scope.$index)}},model:{value:(scope.row.stock),callback:function ($$v) {_vm.$set(scope.row, "stock", $$v)},expression:"scope.row.stock"}})],1)]}}])})],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }