<template>
  <div>
    <el-form :model="form" ref="form" label-width="150px">
      <el-form-item label="选择规格 :" prop="specsIds" :rules="[
          { required: true, message: '请选择规格', trigger: 'change' }
      ]">
        <div class="specsIds">
          <el-select size="small" v-model="form.specsIds" multiple placeholder="请选择规格" @change="changeSpecs">
            <el-option
                v-for="item in specs"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <div v-for="(item,index) in form.specsList" :key="index">
        <el-form-item label="规格名称">
          <el-tag type="info" size="medium" closable @close="handleClose(index)">{{ item.name }}</el-tag>
        </el-form-item>
        <el-form-item label="子规格" :prop="`specsList.${index}.checkList`" :rules="[
            { required: true, message: '请选择子规格', trigger: 'change' }
        ]">
          <el-checkbox-group v-model="item.checkList">
            <el-checkbox v-for="(itemC,indexC) in item.children"
                         :key="itemC.id"
                         :label="itemC.id"
                         @change="checked($event,index,indexC)">
              {{ itemC.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>

      <el-table
          :data="form.specsData"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#eef1f6',
            color: '#606266',
            textAlign: 'center',
          }"
      >
        <template v-slot:empty>
          <p>暂无规格</p>
        </template>
        <el-table-column v-for="(item,index) in form.specsList" :key="index" :prop="`specs_${index}`" :label="item.name" width="200px" align="center"></el-table-column>
        <el-table-column label="规格图片" prop="image" width="150" align="center">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.image`" :rules="[
              { required: true, message: '请上传图片', trigger: 'change' }
          ]" label-width="0px" :inline-message="true">
              <div class="skuImg">
                <UploadImage label="specsData" :url="scope.row.image" type="list" :index="scope.$index" @upload="upload"/>
              </div>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="*SKU编码" prop="sku" width="300">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.sku`" :rules="[
              { required: true, message: '请输入SKU编码', trigger: 'blur' }
          ]" label-width="0px" :inline-message="true">
              <el-input v-model="scope.row.sku"
                        placeholder="请输入SKU编码"
                        maxlength="30"
                        :show-word-limit="true"
                        type="text"
                        @input="changeSkuCode($event,'sku',scope.$index)"/>
            </el-form-item>
<!--            onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"-->
          </template>
        </el-table-column>
        <el-table-column label="*蜜豆积分" prop="points" width="200">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.points`" :rules="[
              { required: true, message: '请输入蜜豆积分', trigger: 'blur' }
          ]" label-width="0px" :inline-message="true">
              <el-input v-model="scope.row.points"
                        placeholder="请输入蜜豆积分"
                        onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                        @blur="salaryChange($event,'points',scope.$index)"
                        type="text"
                        clearable/>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="*成本积分" prop="costPoints" width="200">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.costPoints`" :rules="[
              { required: true, message: '请输入成本积分', trigger: 'blur' }
          ]" label-width="0px" :inline-message="true">
              <el-input v-model="scope.row.costPoints"
                        placeholder="请输入成本积分"
                        onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                        @blur="salaryChange($event,'costPoints',scope.$index)"
                        type="text"
                        clearable/>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="重量(kg)" prop="weight" width="200">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.weight`"
                          label-width="0px" :inline-message="true">
              <el-input v-model="scope.row.weight"
                        placeholder="请输入重量"
                        type="text"
                        onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                        @blur="salaryChange($event,'weight',scope.$index)"/>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="体积(m³)" prop="volume" width="200">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.volume`"
                          label-width="0px" :inline-message="true">
              <el-input v-model="scope.row.volume"
                        placeholder="请输入体积"
                        type="text"
                        onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                        @blur="salaryChange($event,'volume',scope.$index)"/>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="*库存" prop="stock" width="200">
          <template #default="scope">
            <el-form-item :prop="`specsData.${scope.$index}.stock`" :rules="[
              { required: true, message: '请输入库存', trigger: 'change' }
          ]" label-width="0px" :inline-message="true">
              <el-input v-model="scope.row.stock"
                        placeholder="请输入库存"
                        type="text"
                        onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                        @blur="salaryChange($event,'stock',scope.$index)" />
            </el-form-item>
          </template>
        </el-table-column>

      </el-table>

    </el-form>
  </div>
</template>

<script>
import {listSpec, listSpecChild} from "@/api/shop/goods";
import UploadImage from "@/components/UploadImage";

export default {
  name: "createTwo",
  components: {
    UploadImage
  },
  data(){
    return{
      supplierId: '',
      specs: [],
      form: {
        specsIds: [], //选中的规格id
        specsList: [], //选中的规格列表
        specsData: [], //规格项数据
      },
      // 备份规格列数组
      specsArrayBackup: [],
      // 规格项自用添加
      params: {
        'image': '',
        'sku': '',
        'points': '',
        'costPoints': '',
        'weight': '',
        'volume': '',
        'stock': ''
      },
    }
  },
  methods: {
    async setForm(form) {
      this.form.specsIds = form.specsIds;
      this.specsArrayBackup = JSON.parse(JSON.stringify(form.specsData))
      // console.log(form.spec)
      // 创建数组array,将form.spec数组和this.specs数组通过id对比,如果相同则将this.specs数组中相同的部分添加到array数组中
      let specArray = [];
      for (const item1 of form.spec) {
        // console.log('item1', item1)
        for (const item2 of this.specs) {
          // console.log('item2', item2)
          if (item1.id === item2.id) {
            item2.checkList = item1.childIds;
            // console.log('checkedList', checkedList)
            item2.checkedList = await this.setCheckedList(item1.childIds, item2.children);
            specArray.push(item2);
          }
        }
      }
      // console.log(specArray);
      this.form.specsList = specArray;
      const array = await this.onloadCartesianProduct();
      // console.log('array',array);
      this.form.specsData = this.cartesianProductOf(array)
      // console.log('this.form.specsData',this.form.specsData);
      this.specsArrayBackup.forEach(item1=>{
        this.form.specsData.forEach(item2=>{
          if(item1.specIds === item2.specIds){
            item2.image = item1.image;
            item2.sku = item1.sku;
            item2.points = item1.points;
            item2.costPoints = item1.costPoints;
            item2.weight = item1.weight;
            item2.volume = item1.volume;
            item2.stock = item1.stock;
          }
        })
      })
    },
    setCheckedList(arr1,arr2){
      // console.log(arr1,arr2);
      return new Promise((resolve)=>{
        const arr = arr1.map((item)=>{
          return arr2.find(i => i.id === item)
        })
        resolve(arr);
      })
    },
    async listSpecId(id){
      if(this.supplierId !== id){
        this.form.specsIds = [];
        this.form.specsList = [];
        this.form.specsData = [];
        this.specsArrayBackup = [];
        await this.listSpec(id);
        this.supplierId = id;
      }
    },
    async setSupplierId(id){
      this.supplierId = id;
      await this.listSpec(id);
    },
    // 多规格组合
    onloadCartesianProduct() {
      return new Promise((resolve)=>{
        const arr = [];
        this.form.specsList.map(item => {
          if(item.checkedList.length > 0){
            arr.push(item.checkedList)
          }
        })
        resolve(arr);
      })
    },
    // 多规格计算
    cartesianProductOf(array) {
      // console.log('array',array)
      const result = array.reduce(function (prev, curr) {
        return prev.map(function (x) {
          return curr.map(function (y) {
            return x.concat(y);
          });
        }).reduce(function (a, b) {
          return a.concat(b);
        }, []);
      }, [[]]);
      // const result2 = new Array(result.length);
      // for (let i = 0; i < result.length; i++) {
      //   const item = {
      //     specIds: '',
      //     specNames: '',
      //     ...this.params
      //   }
      //   for (let j = 0; j < result[i].length; j++) {
      //     if(item.specIds === '') {
      //       item.specIds = result[i][j].id
      //     }else{
      //       item.specIds += ',' + result[i][j].id
      //     }
      //     item[`specs_${j}`] = result[i][j].name;  // 必须 规格项名称
      //
      //     if(item.specNames === '') {
      //       item.specNames = result[i][j].name
      //     }else{
      //       item.specNames += ',' + result[i][j].name
      //     }
      //   }
      //   result2.push(item);
      //   if(i === result.length - 1){
      //     return result2;
      //   }
      // }
      const result2 = result.map((a)=>{
          const item = {
            specIds: '',
            specNames: '',
            ...this.params
          }
        a.map((b,bi)=>{
          if(item.specIds === '') {
            item.specIds = b.id
          }else{
            item.specIds += ',' + b.id
          }
          item[`specs_${bi}`] = b.name;  // 必须 规格项名称

          if(item.specNames === '') {
            item.specNames = b.name
          }else{
            item.specNames += ',' + b.name
          }
        })
        return item
      })
     return result2;
    },
    // 获取规格列表
    async listSpec(id){
      const { data } = await listSpec({id: id})
      for (const item of data) {
        item.checkList = [];
        item.checkedList = [];
        item.children = await this.listSpecChild(item.id)
      }
      // console.log('data',data);
      this.specs = data || []
    },
    // 获取子规格列表
    listSpecChild(e){
      return new Promise((resolve,reject) => {
        listSpecChild({id: e}).then(({data}) => {
          const newSpecSon = data.map(item => {
            const { ...rest } = item;
            return {
              ...rest
            }
          })
          // console.log('newSpecSon',newSpecSon);
          resolve(newSpecSon)
        }).catch(() => {
          reject([])
        })
      })
    },
    // 选中规格
    async changeSpecs(e){
      // console.log(e);
      this.form.specsList = e.map(item => {
        return this.specs.find(i => i.id === item)
      })
      console.log(this.form.specsList)
      await this.updateSpecsData()
    },
    // 选中子规格
    async checked(e,index,indexC){
      if(e){
        this.form.specsList[index].checkedList.push({
          id: this.form.specsList[index].children[indexC].id,
          name: this.form.specsList[index].children[indexC].name,
        })
        await this.updateSpecsData()
      }else{
        this.form.specsList[index].checkedList.map((item,i) => {
          if(item.id === this.form.specsList[index].children[indexC].id){
            this.form.specsList[index].checkedList.splice(i,1)
          }
        })
        await this.updateSpecsData()
      }
    },
    // 上传图片
    upload(e){
      // console.log(e);
      this.form[e.label][e.index].image = e.image;
      this.$refs.form.clearValidate(`specsData.${e.index}.image`);
      // 更新备份规格项数据
      this.specsArrayBackup = JSON.parse(JSON.stringify(this.form.specsData))
    },
    // 删除选中规格
    handleClose(index) {
      this.specs[index].checkList = [];
      this.specs[index].checkedList = [];
      this.form.specsList.splice(index, 1);
      this.form.specsIds = this.form.specsList.map(item => item.id)
      this.updateSpecsData();
    },
    // 将最新规格项数据和备份规格数据对比,如果有相同的ids,则更新最新规格项数据
    async updateSpecsData(){
      const array = await this.onloadCartesianProduct();
      if(array.length > 0) {
        this.form.specsData = await this.cartesianProductOf(array)
        console.log(this.form.specsData)
        console.log(this.specsArrayBackup)
        // 将两个数组this.form.specsData和this.specsArrayBackup,如果数组中存在相同的ids,则更新this.form.specsData数据
        this.form.specsData.map((item,i) => {
          this.specsArrayBackup.map((itemBackup,iBackup) => {
            if(item.specIds === itemBackup.specIds){
              Object.keys(itemBackup).map(a=>{
                this.form.specsData[i][a] = itemBackup[a]
              })
            }
          })
        })
      }else{
        this.form.specsData = []
      }
    },
    salaryChange(e,t,i){
      const value = e.target.value;
      const max = 999999;
      if(value) {
        if(isNaN(value * 1)){
          this.form.specsData[i][t] = '';
          e.target.value = '';
        }else{
          if(t === 'stock' || t === 'points'){
            if(value > max){
              this.form.specsData[i][t] = max;
              e.target.value = max;
            }else{
              this.form.specsData[i][t] = value * 1;
              e.target.value = value * 1;
            }
          }else{
            if(value > max){
              this.form.specsData[i][t] = max.toFixed(2);
              e.target.value = max.toFixed(2);
            }else{
              this.form.specsData[i][t] = parseFloat(value).toFixed(2);
              e.target.value = parseFloat(value).toFixed(2);
            }
          }
          // 更新备份规格项数据
          this.specsArrayBackup = JSON.parse(JSON.stringify(this.form.specsData))
        }
      }else{
        this.form.specsData[i][t] = '';
      }
    },
    submitForm(){
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(this.form)
          } else {
            resolve(false)
          }
        });
      })
    },
    changeSkuCode(e,t,i){
      const pattern = '^[0-9a-zA-z]{0,30}$';
      const regExp = new RegExp(pattern)
      if(e.match(regExp)) {
        this.form.specsData[i][t] = e;
      }else{
        this.form.specsData[i][t] = '';
        e = '';
      }
    }
  }
}
</script>

<style scoped>
/deep/ .el-table .el-form-item{
  margin-bottom: 0;
}
.specsIds /deep/.el-select{
  width: 500px;
}
</style>