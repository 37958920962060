import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/shop/goods/list', params);

export const deleteNew = params => Delete(`/web/shop/goods/delete/${params.id}`, '')

// /web/shop/goods/classify/list get
export const listClassify = params => get('/web/shop/goods/classify/list', params);

// /web/shop/goods/recommend/update put
export const updateRecommend = params => put('/web/shop/goods/recommend/update', params);

// /web/shop/goods/state/update put
export const updateState = params => put('/web/shop/goods/state/update', params);

// /web/shop/goods/add post
export const add = params => post('/web/shop/goods/add', params);

// /web/shop/goods/supplier/list get
export const listSupplier = params => get('/web/shop/goods/supplier/list', params);

// /web/shop/goods/spec/list/{id} get
export const listSpec = params => get('/web/shop/goods/spec/list/' + params.id, '');

// /web/shop/goods/spec/child/list/{id} get
export const listSpecChild = params => get('/web/shop/goods/spec/child/list/' + params.id, '');

export const update = params => put('/web/shop/goods/update', params);

// /web/shop/goods/view/{id} get
export const view = params => get('/web/shop/goods/view/' + params.id, '');