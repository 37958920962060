<template>
  <div class="main-content">
    <h3 class="marginBottom20">新增商品</h3>
    <el-tabs v-model="activeName" :before-leave="beforeLeave">
      <el-tab-pane label="基本信息" name="first">
        <createOne ref="createOne" @onload="onload"/>
        <div class="flex align-items justify-content-center marginTop20">
            <el-button size="medium" type="primary" @click="nextStep('first')">下一步</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="规格信息" name="second">
        <createTwo ref="createTwo"/>
        <div class="flex align-items justify-content-center marginTop20">
            <el-button size="medium" @click="previousStep('first')">上一步</el-button>
            <el-button size="medium" type="primary" @click="nextStep('second')">下一步</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="商品详情" name="third">
        <createThree ref="createThree"/>
        <div class="flex align-items justify-content-center">
            <el-button size="medium" @click="previousStep('second')">上一步</el-button>
          <el-button size="medium" :disabled="submitType" type="primary" @click="handleSubmit">提交</el-button>
          <el-button size="medium" @click="handleBack">取消</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import createOne from "./components/createOne.vue";
import createTwo from "./components/createTwo.vue";
import createThree from "./components/createThree.vue";
import {add, update, view} from '@/api/shop/goods'
export default {
  name: "info",
  components: {
    createOne,
    createTwo,
    createThree
  },
  data(){
    return{
      id: this.$route.query.id,
      activeName: 'first',
      formOne: {},
      formTwo: {},
      formThree: {},
      submitType: false, // 避免重复点击
    }
  },
  mounted() {

  },
  methods: {
    async onload(){
      if(this.id){
        await this.getView()
      }
    },
    previousStep(e){
      this.activeName = e;
    },
    async nextStep(stop){
      if(stop === 'first'){
          const dataOne = await this.$refs.createOne.submitForm();
          return new Promise((resolve, reject) => {
            if(dataOne){
              // console.log('dataOne',dataOne);
              this.$refs.createTwo.listSpecId(dataOne.supplierId);
              const { classifyId, ...rest } = dataOne;
              let classifyOneId = '';
              let classifyTwoId = '';
              let classifyThreeId = '';
              if(classifyId.length === 1){
                classifyOneId = classifyId[0];
              }else if(classifyId.length === 2){
                classifyTwoId = classifyId[0];
                classifyTwoId = classifyId[1];
              } else if(classifyId.length === 3){
                classifyOneId = classifyId[0];
                classifyTwoId = classifyId[1];
                classifyThreeId = classifyId[2];
              }
              this.formOne = {
                classifyOneId,
                classifyTwoId,
                classifyThreeId,
                ...rest
              };
              this.activeName = 'second'
              // console.log('this.formOne',this.formOne);
              resolve();
            }else{
              reject();
            }
          })
        }
        if(stop === 'second'){
          const dataTwo = await this.$refs.createTwo.submitForm();
            return new Promise((resolve, reject) => {
              if(dataTwo){
                const { specsData,specsList } = dataTwo;
                const spec = specsList.map(item => {
                  return {
                    id: item.id,
                    childIds: item.checkList
                  }
                });
                this.formTwo = {
                  specDetail: specsData,
                  spec,
                }
              this.activeName = 'third'
                // console.log('this.formTwo',this.formTwo);
                resolve();
              }else{
                reject();
              }
            })
        }
    },
    async beforeLeave(route, stop){
      // console.log(route, stop);
        if(stop === 'first'){
          const dataOne = await this.$refs.createOne.submitForm();
          return new Promise((resolve, reject) => {
            if(dataOne){
              // console.log('dataOne',dataOne);
              this.$refs.createTwo.listSpecId(dataOne.supplierId);
              const { classifyId, ...rest } = dataOne;
              let classifyOneId = '';
              let classifyTwoId = '';
              let classifyThreeId = '';
              if(classifyId.length === 1){
                classifyOneId = classifyId[0];
              }else if(classifyId.length === 2){
                classifyTwoId = classifyId[0];
                classifyTwoId = classifyId[1];
              } else if(classifyId.length === 3){
                classifyOneId = classifyId[0];
                classifyTwoId = classifyId[1];
                classifyThreeId = classifyId[2];
              }
              this.formOne = {
                classifyOneId,
                classifyTwoId,
                classifyThreeId,
                ...rest
              };
              // console.log('this.formOne',this.formOne);
              resolve();
            }else{
              reject();
            }
          })
        }else if(stop === 'second'){
          if(route === 'first'){
            const dataOne = await this.$refs.createOne.submitForm();
            return new Promise((resolve, reject) => {
              if(dataOne){
                resolve();
              }else{
                reject();
              }
            })
          }else{
            const dataTwo = await this.$refs.createTwo.submitForm();
            return new Promise((resolve, reject) => {
              if(dataTwo){
                const { specsData,specsList } = dataTwo;
                const spec = specsList.map(item => {
                  return {
                    id: item.id,
                    childIds: item.checkList
                  }
                });
                this.formTwo = {
                  specDetail: specsData,
                  spec,
                }
                // console.log('this.formTwo',this.formTwo);
                resolve();
              }else{
                reject();
              }
            })
          }
        }else if(stop === 'third'){
          // const dataThree = await this.$refs.createThree.submitForm();
          // console.log('dataThree',dataThree);
          const three = true;
          return new Promise((resolve, reject) => {
            if(three){
              // this.formThree = dataThree;
              // console.log('this.formThree',this.formThree);
              resolve();
            }else{
              reject();
            }
          })
        }
    },
    async handleSubmit(){
      const dataThree = await this.$refs.createThree.submitForm();
      if(dataThree){
        this.formThree = dataThree;
        // console.log('this.formThree',this.formThree);
        // console.log(this.id)
        this.submitType = true;
        if(this.id){
          update({
            id: this.id,
            ...this.formOne,
            ...this.formTwo,
            ...this.formThree
          }).then(res => {
            if (res.code === 200){
              this.$message.success('更新成功');
              this.$router.go(-1);
            }else{
              this.$message.error(res.msg);
              this.submitType = false;
            }
          })
        }else {
          add({
            ...this.formOne,
            ...this.formTwo,
            ...this.formThree
          }).then(res => {
            if(res.code === 200){
              this.$message.success('添加成功');
              this.$router.go(-1);
            }else{
              this.$message.error(res.msg);
              this.submitType = false;
            }
          })
        }
      }
    },
    handleBack(){
      this.$router.go(-1);
    },
    async getView(){
      const { data } = await view({id: this.id});
      // console.log('data',data);
      const { classifyOneId,classifyTwoId,classifyThreeId, ...rest } = data;
      const formOne = {
        name: data.name,
        supplierId: data.supplierId,
        classifyId: [classifyOneId,classifyTwoId,classifyThreeId],
        sort: data.sort,
        virtualSales: data.virtualSales || '',
        isRecommend: data.isRecommend,
        state: data.state,
        cover: data.cover,
        video: data.video,
        banner: data.banner,
      }
      this.formOne = {
        name: data.name,
        supplierId: data.supplierId,
        classifyOneId: data.classifyOneId,
        classifyTwoId: data.classifyTwoId,
        classifyThreeId: data.classifyThreeId,
        sort: data.sort,
        virtualSales: data.virtualSales || '',
        isRecommend: data.isRecommend,
        state: data.state,
        cover: data.cover,
        video: data.video,
        banner: data.banner,
      }
      this.$refs.createOne.setForm(formOne);
      await this.$refs.createTwo.setSupplierId(data.supplierId);
      const specsIds = data.spec.map(item => {
        return item.id
      });
      // console.log('specsIds',specsIds);
      const formTwo = {
        specsIds,
        specsData: data.specDetail,
        spec: data.spec,
      }
      this.formTwo = {
        spec: data.spec,
        specDetail: data.specDetail
      }
      await this.$refs.createTwo.setForm(formTwo);
      const formThree = {
        details: data.details
      }
      this.formThree = {
        details: data.details
      }
      await this.$refs.createThree.setForm(formThree);
    }
  }
}
</script>

<style scoped>

</style>